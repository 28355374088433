@import '../../../index.scss';

// Liderazgo banner
.Liderazgo-banner-container {

    justify-content: start;
    background-image: url(../../../assets/images/banner-liderazgo.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(94, 215, 242, 0.6);
    background-blend-mode: soft-light;
    min-height: 300px;
    margin: 0;
    padding: 3rem 6rem;

    @media only screen and (max-width: $breakpoint-960) {
        padding: 0.5rem;
        justify-content: center;
        min-height: 250px;
    }

    .logos-left {
        display: flex;
        align-items: start;

        @media only screen and (max-width: $breakpoint-960) {
            justify-content: center;
            align-items: center;
        }

        .logo-1 {
            height: 2.5rem;
            padding-right: 1rem;
        }

        .logo-2 {
            height: 3rem;
            padding: 0rem 1rem;
        }

        .right-border {
            border-right: 2px solid $white;
            min-height: 3.2rem;
        }

    }

    .content-right {
        display: flex;
        align-items: center;

        .title {
            font-size: 75px;
            color: $black;
            text-align: right;
            font-family: $Ancizar-bold-serif;
            background-color:rgba(94, 215, 242, 0.8);
            padding-right: 8px;
            border-radius: 4px;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 3.25rem;
                text-align: center;
            }
        }

    }

}

// Liderazgo description
.Liderazgo-description-container {

    background-color: $blue-light;
    padding: 3rem 0rem;

    @media only screen and (max-width: $breakpoint-960) {
        padding: 1rem 0rem;
    }

    .text-container {

        display: flex;
        justify-content: center;
        padding: 1.5rem 0rem;

        .title {
            font-size: 30px;
            text-align: center;
            font-family: $Ancizar-bold-serif;
            color: $gray;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 24px;
            }
        }

        .text-info {
            font-size: 24px;
            font-family: $Ancizar-light-serif;
            text-align: center;
            color: $gray;
            line-height: 1.2 !important;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 20px;
            }
        }

    }


}

// Liderazgo media
.Liderazgo-media-container {

    background-color: $white;
    padding: 2rem 0;

    @media only screen and (max-width: $breakpoint-960) {
        padding: 1rem 0;
    }

    .text-container {
    
        display: flex;
        justify-content: center;
        padding: 1.5rem 0rem;

        .title {
            font-size: 40px;
            text-align: center;
            font-family: $Ancizar-bold-serif;
            color: $purple;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 30px;
            }
        }

    }

    .media-section {

        border-right: 3px solid $purple-soft;
        display: flex;
        align-content: center;
        justify-content: center;

        @media only screen and (max-width: $breakpoint-960) {
            border-right: 0px solid $blue;
        }

        .info-container {
            display: flex;
            justify-content: center;

            .title {
                font-size: 35px;
                font-family: $Ancizar-bold-serif;
                color: rgb(51, 51, 51, 0.8);
                width: 100%;
                text-align: center;
                min-height: 7rem;
                align-items: center;
                display: flex;
                justify-content: center;

                @media only screen and (max-width: $breakpoint-960) {
                    font-size: 24px;
                    min-height: auto;
                    padding-top: 1.5rem;
                }
            }

            .video-item {
                width: 100%;
                display: flex;
                padding: 1.5rem 0px;
                align-items: center;
                justify-content: space-evenly;

                .text {
                    font-size: 20px;
                    min-height: 8rem;
                    //display: flex;
                    align-items: center;

                    @media only screen and (max-width: $breakpoint-960) {
                        font-size: 18px;
                        min-height: auto;
                    }
            }

                .info-img {
                    width: 5rem;
                    @media only screen and (max-width: $breakpoint-960) {
                        display: none;
                    }
                }
            }
        }
        .badge-wrapper{
            
            display: flex;
            flex-direction: column;
            flex-grow: 1; 
            flex-shrink: 1;

            .badge-container{
                flex-grow: 1; 
                flex-shrink: 1;
                margin: 10px;
                flex-wrap:nowrap;
                
            }
            .custom-badge .MuiBadge-badge{
                transform: none !important;
                left: 0px;
            }
            
        }
    }

}

// Liderazgo images
.Liderazgo-images-container {

    padding: 4rem 0rem;
    background-color: $white;

    @media only screen and (max-width: $breakpoint-960) {
        padding: 0rem;
    }

    .text-container {
    
        display: flex;
        justify-content: center;
        padding: 1.5rem 0rem;

        .title {
            font-size: 40px;
            text-align: center;
            font-family: $Ancizar-bold-serif;
            color: rgba(141, 23, 170, 1);
            small{
                color: #333333;
                font-size: 2rem;
            }

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 30px;
            }
        }

    }

    .graphic-container {

        padding: 2.5rem 2.5rem 0 0;

        @media only screen and (max-width: $breakpoint-960) {
            padding: 0;
        }

        .graphic-img {
            height: 35rem;
            width: auto;
            text-align: center;
            transition-duration: 0.2s;
            cursor: pointer;

            @media only screen and (max-width: $breakpoint-960) {
                width: 15rem;
                height: auto;
            }
        
            &:hover {
                transform: scale(1.05);
            }
        }
    }
    // src/components/LandingPage/LiderazgoMinisterio/LiderazgoSection.scss

    .download-button {
        background-color: #8D17AA;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        transition: background-color 0.3s;

        &:hover {
            background-color: #5E0099;
        }

        &:focus {
            border: 2px solid #D1A2DD;
        }

        &:active {
            background-color: #D9488B;
        }

        img {
            margin-right: 8px;
            width: 20px;
            height: 20px;
        }
    }

}

// Liderazgo encuentros
.Liderazgo-encuentros-container {

    padding: 4rem 0rem;
    background-color: $white;

    .separator {
        border-width: 2px;
        border-color: #b7b7b7;
        margin-left: auto;
        margin-right: auto;
    }

    .text-container {
    
        display: flex;
        justify-content: center;
        padding: 1.5rem 0rem;

        .title {
            font-size: 40px;
            text-align: center;
            font-family: $Ancizar-bold-serif;
            color: $purple;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 24px;
            }
        }

        .text-info {
            font-size: 24px;
            font-family: $Ancizar-light-serif;
            text-align: center;
            color: $gray;
            line-height: 1.2 !important;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 20px;
            }
        }

    }

    .card-container {

        padding: 0 2.5rem;

        &.right-border {
            border-right: 3px solid #f5894a;
    
            @media only screen and (max-width: $breakpoint-960) {
                border-right: none;
            }
        }

        .card-info-container2 {

            display: flex;
            justify-content: center;
            justify-items: center;
            padding: 1rem 0rem;

            .img-item {
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                transition-duration: 0.2s;
                cursor: pointer;
                position: relative;

                &:hover {
                    transform: scale(1.05);
                }
                &::after {
                    content: ' ';
                    background: #8D17AA url('../../../assets/images/ico-plus.svg'); 
                    //background-size: cover; 
                    width: 50px; 
                    height: 50px;
                    border-radius: 8px 0 8px 0; 
                    position: absolute; 
                    bottom: 0px; 
                    right :0px; 
                    z-index: 1; 
                    cursor: pointer;
                }

                .info-img {
                    width: 100%;
                    border-radius: 8px;
                    cursor: pointer;
                }
            }
            
            

            .text-container {

                display: flex;
                justify-content: center;
                flex-direction: column;

                .title {
                    font-size: 24px;
                    text-align: left;
                    font-family: $Ancizar-bold-serif;
                    color: #8d17aa;
                    padding: 0.5rem 0 0.2rem;
                }

                .subtitle {
                    font-size: 1.5rem;
                    font-family: 'Ancizar Sans Regular';
                    color: $gray; 
                    text-align: left; 
                }

                .text {
                    font-size: 20px;
                }
            }
        }
    }

}

.modal-encuentros {

    .text-container {
    
        display: flex;
        justify-content: center;

        .title {
            font-size: 30px;
            text-align: center;
            font-family: $Ancizar-bold-serif;
            color: rgb(51, 51, 51, 0.8);

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 24px;
            }
        }

        .text-info {
            font-size: 20px;
            font-family: $Ancizar-light-serif;
            color: $gray;
            line-height: 1.2 !important;
            padding: 0 2rem 2rem 2rem;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 20px;
            }
        }

    }

    .encuentros-carousel {
        margin: 2rem 0rem;

        .encuentros-carousel-slide {
            display: flex;
            justify-content: center;

            .encuentros-img {
                cursor: pointer;
                height: 25rem;
                width: auto !important;
                margin: 2rem 0rem;
            }
        }
    }

}

.swiper-button-next,
.swiper-button-prev {
    color: $purple !important;
}

// Liderazgo repository
.Liderazgo-repository-container {
    padding: 0 0 3rem 0;
    background-color: $white;

    .separator {
        border-width: 2px;
        border-color: #b7b7b7;
        margin-left: auto;
        margin-right: auto;
    }

    .text-container {
        display: flex;
        justify-content: center;
        padding: 0 0 0.5rem 0;

        .title {
            font-size: 45px;
            text-align: center;
            font-family: $Ancizar-bold-serif;
            color: $purple;
            padding: 3rem 0;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 30px;
                padding: 1rem 0;
            }
        }

    }

    .bullet {
        p{
            padding-left: 10px;
            margin-left: 20px;
        }

        position: relative;
        padding-left: 20px;
    
        &::before {
            content: ' ';
            position: absolute;
            left: 15px; 
            top: 40%;
            transform: translateY(-50%); 
            width: 10px; 
            height: 10px; 
            border-radius: 50%; 
            opacity: 0.5; 
            transition: opacity 0.3s ease; 
        }
    
        &.color-1::before {
            background-color: rgba(0, 51, 102, 1); 
        }
    
        &.color-2::before {
            background-color: rgba(0, 102, 51, 1); 
        }
    
        &.color-3::before {
            background-color: rgba(255, 204, 0, 1);
        }
    
        &.color-4::before {
            background-color: rgba(204, 102, 0, 1);
        }
    
        &.color-5::before {
            background-color: rgba(153, 0, 0, 1); 
        }
        &.color-6::before {
            background-color: rgba(128, 128, 128, 1);
        }
        &.color-7::before {
            background-color: rgba(102, 0, 153, 1);
        }
        
    }

    .cards-info-container {

        padding: 1rem 0rem;
        justify-content: center;

        .img-item {
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            .info-img {
                width: 7rem;

                @media only screen and (max-width: $breakpoint-960) {
                    width: 5rem;
                    padding: 2rem 0 1rem 0;
                }
            }
        }

        &:hover .bullet {
            opacity: 1; // Cambia la opacidad a 100% en hover
        }

        &:hover .bullet::before {
            opacity: 1; // Cambia la opacidad del bullet a 100% en hover
        }

        .text-container-2 {
            align-items: center;

            @media only screen and (max-width: $breakpoint-960) {
                justify-content: center;
            }

            .text {
                font-size: 35px;
                font-family: $Ancizar-bold-serif;
                color: rgb(51, 51, 51, 0.8);

                @media only screen and (max-width: $breakpoint-960) {
                    font-size: 24px;
                    text-align: center; 
                }
            }
        }

        
    }

    .resources-section {

        display: flex;
        align-content: start;
        justify-content: center;
        padding: 0 1rem;
        border-right: 2px solid #b7b7b7;

        @media only screen and (max-width: $breakpoint-960) {
            padding: 0;
            border-right: none;
        }

        .cards-container {

            .link-cards {

                text-decoration: none;
                height: 100%;
                display: flex;
                box-shadow: 2px 2px 8px #929292e3;
                transition-duration: 0.2s;
                cursor: pointer;

                @media only screen and (max-width: $breakpoint-960) {
                    margin: 0 1rem;
                }

                &:hover{
                    transform: scale(1.05);
                }

                .card-info-container {
                
                    display: flex;
                    justify-content: center;
                    justify-items: center;
                    padding: 1rem;  
            
                    &.color-1 {
                        background-color: #f5f5f5;
                        background: linear-gradient(90deg, rgba(239,222,243,1) 22%, rgba(250,245,252,1) 23%, #f5f5f5 100%);
                        
                    }
            
                    &.color-2 {
                        background-color: #f9f3fb;
                        background: linear-gradient(90deg, rgba(239,222,243,1) 22%, rgba(250,245,252,1) 23%, #f9f3fb 100%);
                    }
                    
                    .card-image{
                        width: 120px;
                        margin: 0 14px 0 -14px;
                    }

                    .text-container {
                        padding-left: 1rem;
                        color: $gray;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-items: center;
            
                        .text {
                            font-size: 18px;
                        }
                    }
                }
            }

        }

    }

    .law-section {

        display: flex;
        align-content: start;
        justify-content: center;
        //flex-direction: column;

        .text-container-3 {
        
            display: flex;
            justify-content: center;
    
            .title {
                font-size:24px;
                font-family: $Ancizar-bold-serif;
                color: $purple;
                padding: 2rem 0;
            }
    
        }

        .cards-container-2 {

            hr {
                border-width: 2px;
                border-color: #b7b7b7;
                margin-left: auto;
                margin-right: auto;

                &.first {
                    display: none;
                }
            }

            .link-cards-text {

                text-decoration: none;
                cursor: pointer;

                .card-info-container {

                    display: flex;
                    justify-content: center;
                    justify-items: center;
                
                    .text-container {
                        padding-left: 1rem;
                        align-items: flex-start;
                        flex-direction: column;
                        display: flex;
                
                        .text {
                            font-size: 18px;    
                            color: $gray;

                            &:hover{
                                color: rgb(51, 51, 51, 0.8);
                            }
                        }
                    }
                }
            }

        }

    }
}
.video-item-iframe{
    width: 90%;
    height: 25rem;
}