@import '../../../index.scss';

.footer-container {

  height: auto;
  padding: 0;
  line-height: 1.7em;

  .top-footer{

    background-color: $light-gray;
    padding: 2rem;
    font-size: 1.3em;

    @media only screen and (max-width: $breakpoint-960) {
      padding: 1.5rem;
    }

    .logos-footer {
    
      display: flex;
      align-items: center;
  
      .logo-right {
        justify-content: end;
        display: flex;

        @media only screen and (max-width: $breakpoint-960) {
          justify-content: center;
        }
  
        &.right-border {
          border-right: 2px solid $black;
        }
      }
  
      .logo-1-footer {
        height: 4rem;
        padding-right: 1rem;

        @media only screen and (max-width: $breakpoint-960) {
          margin: 1rem 0 2rem 0;
        }
      }
  
      .logo-2-footer {
        height: 3rem;
        padding: 0rem 1rem;
      }
  
    }
  
    .nav-footer {
  
      justify-content: end;
      display: flex;

      @media only screen and (max-width: $breakpoint-960) {
        justify-content: center;
      }
  
      nav {
  
        width: 13rem;

        @media only screen and (max-width: $breakpoint-960) {
          width: auto;
          padding: 2rem 2rem 0 2rem;
        }
  
        a {
          display: inline-block;
          width: 100%;
          color: $gray;
          font-family: $Ancizar-regular-serif;
        }
      }
  
    }
  }

  .bot-footer {

    background-color: $black;
    font-family: $Ancizar-regular-serif;
    color: $light-gray;
    padding: 1rem 3rem;
    font-size: 1.5em;

    @media only screen and (max-width: $breakpoint-960) {
        font-size: 1.25em;
      }

  }

}