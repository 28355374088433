@import '../../../index.scss';

// Regiones banner
.Regiones-banner-container {

    justify-content: start;
    background-image: url(../../../assets/images/cambio-regiones-bg.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgb(242, 107, 29, 0.6);
    background-blend-mode: soft-light;
    min-height: 300px;
    margin: 0;
    padding: 3rem 6rem;

    .logos-left {
        display: flex;
        align-items: start;

        .logo-1 {
            height: 2.5rem;
            padding-right: 1rem;
        }

        .logo-2 {
            height: 3rem;
            padding: 0rem 1rem;
        }

        .right-border {
            border-right: 2px solid $white;
            min-height: 3.2rem;
        }

    }

    .content-right {
        display: flex;
        align-items: center;

        .title {
            font-size: 75px;
            color: $white;
            text-align: right;
            font-family: $Ancizar-bold-serif;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 35px;
                text-align: center;
            }
        }

    }

}

// Regiones description
.Regiones-description-container {

    background-color: $orange-light;
    padding: 3rem 0rem;

    .text-container {

        display: flex;
        justify-content: center;
        padding: 1.5rem 0rem;

        .title {
            font-size: 30px;
            text-align: center;
            font-family: $Ancizar-bold-serif;
            color: $gray;
    
            @media only screen and (max-width: $breakpoint-960) {
                font-size: 24px;
            }
        }
    
        .text-info {
            font-size: 24px;
            font-family: $Ancizar-light-serif;
            text-align: center;
            color: $gray;
            line-height: 1.2 !important;
    
            @media only screen and (max-width: $breakpoint-960) {
                font-size: 20px;
            }
        }

    }

}

// Regiones pildoras
.Regiones-pildoras-container {

    background-color: $white;
    padding-top: 3rem;

    .text-container {

        display: flex;
        justify-content: center;
        padding: 1.5rem 0rem;

        .title {
            font-size: 40px;
            text-align: center;
            font-family: $Ancizar-bold-serif;
            color: $purple;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 24px;
            }
        }

        .text-info {
            font-size: 24px;
            font-family: $Ancizar-light-serif;
            text-align: center;
            color: $gray;
            line-height: 1.2 !important;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 20px;
                padding-bottom: 2rem;
            }
        }

    }

    .pildoras-container {

        padding: 2.5rem 2.5rem 0 0;
        position: relative;
        
        

        @media only screen and (max-width: $breakpoint-960) {
            padding: 0;
        }

        .pildora-img {
            height: 22rem;
            width: auto;
            text-align: center;
            transition-duration: 0.2s;
            cursor: pointer;
            position: relative;

            @media only screen and (max-width: $breakpoint-960) {
                width: 20rem;
                height: auto;
            }

            &:hover {
                transform: scale(1.05);
            }
        }

    }

}
.button-container {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 3rem;
    background-color: white;

    .download-button {
        background: $purple; // Color de fondo
        border: solid 1px transparent;
        border-radius: 12px;
        color: $white;
        min-width: 120px;
        min-height: 40px;
        font-size: 15px;
        padding: 10px 20px; // Espaciado interno para hacer el botón más grande
        transition: background 0.3s;

        &:hover {
            background: $pink; // Color de fondo al pasar el mouse
        }
    }
}

.pildoras-modal {
    padding: 2rem !important;

    .subtitle {
        font-size: 24px;
        text-align: center;
        font-family: $Ancizar-bold-serif;
        color: $gray;
    }
}


// Regiones memories
.Regiones-memories-container {

    background-color: $white;
    padding: 0rem 0rem;

    .separator {
        border-width: 2px;
        border-color: #b7b7b7;
        margin-left: auto;
        margin-right: auto;
    }

    .text-container {

        display: flex;
        justify-content: center;
        padding: 1.5rem 0rem;

        .title {
            font-size: 40px;
            text-align: center;
            font-family: $Ancizar-bold-serif;
            color: $purple;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 24px;
            }
        }

        .text-info {
            font-size: 24px;
            font-family: $Ancizar-light-serif;
            text-align: center;
            color: $gray;
            line-height: 1.2 !important;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 20px;
            }
        }

    }

    .swiper-button-next,
    .swiper-button-prev {
        color: $purple !important;
    }

    .memories-carousel {
        background-color: #ebebeb;
        margin: 2rem 0rem;

        .memories-carousel-slide {
            display: flex;
            justify-content: center;

            .memory-img {
                border: solid #adadad 2px;
                transition-duration: 0.2s;
                cursor: pointer;
                height: 20rem;
                width: auto;
                margin: 2rem 0rem;
                position: relative;

                &::after {
                    content: ' ';
                    background: #8D17AA url('../../../assets/images/ico-plus.svg'); 
                    //background-size: cover; 
                    width: 50px; 
                    height: 50px;
                    border-radius: 8px 0 8px 0; 
                    position: absolute; 
                    bottom: 0px; 
                    right :0px; 
                    z-index: 1; 
                    cursor: pointer;
                }

                @media only screen and (max-width: $breakpoint-960) {
                    height: 10rem;
                }

                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }

}

// Regiones media
.Regiones-media-container {

    background-color: $white;
    padding: 0 0 2rem 0;

    @media only screen and (max-width: $breakpoint-960) {
        padding: 1rem 0;
    }

    .separator {
        border-width: 2px;
        border-color: #b7b7b7;
        margin-left: auto;
        margin-right: auto;
    }

    .text-container {

        display: flex;
        justify-content: center;
        padding: 1.5rem 0rem;

        .title {
            font-size: 40px;
            text-align: center;
            font-family: $Ancizar-bold-serif;
            color: $purple;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 30px;
            }
        }

        .text-info {
            font-size: 24px;
            font-family: $Ancizar-light-serif;
            text-align: center;
            color: $gray;
            line-height: 1.2 !important;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 20px;
            }
        }

    }

    .media-section {

        border-right: 3px solid #f5894a;
        display: flex;
        align-content: center;
        justify-content: center;
        margin: 2rem 0rem;

        @media only screen and (max-width: $breakpoint-960) {
            border-right: 0px solid $blue;
        }

        &.last-item {
            border-right: none !important;
        }

        .info-container {
            display: flex;
            justify-content: center;

            .title {
                font-size: 35px;
                font-family: $Ancizar-bold-serif;
                color: rgb(51, 51, 51, 0.8);
                width: 100%;
                text-align: center;
                min-height: 7rem;
                align-items: center;
                display: flex;
                justify-content: center;

                @media only screen and (max-width: $breakpoint-960) {
                    font-size: 24px;
                    min-height: auto;
                    padding-top: 1.5rem;
                }
            }

            .video-item {
                width: 100%;
                display: flex;
                padding: 1.5rem 0px;
                align-items: center;
                justify-content: space-evenly;

                .text {
                    font-size: 20px;
                    min-height: 5rem;
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: $breakpoint-960) {
                        font-size: 18px;
                        min-height: auto;
                    }
                }

                .info-img {
                    width: 4rem;

                    @media only screen and (max-width: $breakpoint-960) {
                        display: none;
                    }
                }
            }
        }
    }

}
.Regiones-reg-magazin-container{
    background-color: $white;
    padding: 3rem 0rem;
    .image-container {
        position: relative;
        cursor: pointer;
    }
    
    .gallery-image {
        width: 100%;
        transition: 0.3s; 
        padding: 20px 10px;
    }
    
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(217, 72, 140, 0.80); 
        display: flex;
        flex-direction: column; 
        justify-content: center;
        align-items: center;
        opacity: 0; 
        transition: opacity 0.3s; 
        margin: 20px 10px;
    }
      
    .overlay-text {
        color: white; 
        margin-top: 5px; 
        font-size: 16px; 
        text-align: center; 
        font-weight: bold;
    }
    
    .image-container:hover .overlay {
        opacity: 1; /* Mostrar overlay al hacer hover */
    }
    
    .image-container:hover .gallery-image {
        filter: brightness(0.7); /* Oscurecer imagen al hacer hover */
    }
    
    .icon {
        width: 50px; /* Ajusta el tamaño del ícono */
        height: auto;
    }
}

// Regiones classroom
.Regiones-classroom-container, .Regiones-reg-magazin-container {

    background-color: $white;
    padding: 3rem 0rem;

    .separator {
        border-width: 2px;
        border-color: #b7b7b7;
        margin-left: auto;
        margin-right: auto;
    }

    .text-container {

        display: flex;
        justify-content: center;
        padding: 1.5rem 0rem;
        align-items: center;

        .title {
            font-size: 40px;
            text-align: center;
            font-family: $Ancizar-bold-serif;
            color: $purple;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 24px;
            }
        }

        .text-info {
            font-size: 20px;
            font-family: $Ancizar-light-serif;
            color: $gray;
            line-height: 1.2 !important;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 20px;
            }
        }

    }

    .img-container {
        justify-content: center;
        display: flex;
        align-items: center;

        .info-img {
            width: 20rem;
            height: auto;

            @media only screen and (max-width: $breakpoint-960) {
                width: 10rem;
            }
        }
    }

    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;

        .submit-button {
            margin-top: 16px;
            background: $purple;
            border: solid 1px transparent;
            border-radius: 12px;
            color: $white;
            margin: 25px;
            width: auto;
            height: auto;
            min-width: 120px;
            min-height: 40px;
            font-size: 15px;

            &:hover {
                color: $white;
                background: $pink;
            }

            .btn-txt {
                font-size: 18px;
                color: $white;
                font-family: $Ancizar-regular-serif;
            }
        }
    }

}