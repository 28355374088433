@import '../../../index.scss';

// Home banner
.Home-banner-container {

    align-items: end;
    justify-content: start;
    background-image: url(../../../assets/images/banner-image.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 600px;
    margin: 0;
    padding: 3rem 4rem;

    .logos-left {
        display: flex;
        align-items: center;

        .logo-1 {
            height: 2.5rem;
            padding-right: 1rem;
        }

        .logo-2 {
            height: 3rem;
            padding: 0rem 1rem;
        }

        .right-border {
            border-right: 2px solid $white;
            min-height: 3.2rem;
        }

    }

    .logos-right {
        display: flex;
        align-items: center;
        justify-content: end;
        padding: 2rem;

        .logo-1 {
            height: 9rem;
        }

    }

}

// Home description
.Home-description-container {

    background-color: $pink-light;
    padding: 3rem 0rem;

    .text-container {

        display: flex;
        justify-content: center;
        padding: 1.5rem 0rem;

        .title {
            font-size: 50px;
            text-align: center;
            font-family: $Ancizar-bold-serif;
            color: $purple;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 24px;
            }
        }

        .text-info {
            font-size: 26px;
            font-family: $Ancizar-light-serif;
            text-align: justify;
            color: $gray;
            line-height: 1.2 !important;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 20px;
            }
        }

    }


}

// Home media
.Home-media-container {

    background-color: $white;
    padding: 5rem 0rem;

    .first-section {

        border-right: 3px solid $blue;
        display: flex;
        align-content:center;
        justify-content: center;
        
        @media only screen and (max-width: $breakpoint-960) {
            border-right: 0px solid $blue;
        }

        .info-container {
            display: flex;
            justify-content: center;

            .title {
                font-size: 42px;
                font-family: $Ancizar-bold-serif;
                color: $pink;
                width: 100%;
                
            }
    
            .video-item {
                width: 100%;
                display: flex;
                padding: 1.5rem 0px;
                align-items: center;
                justify-content: space-evenly;

                .text {
                    font-size: 20px;
                }

                .info-img {
                    width: 6rem;
                }
            }
        }
    }

    .second-section {
        
        display: flex;
        align-content: center;
        justify-content: center;

        .cards-container {

            hr {
                border-width: 3px;
                border-color: $blue;
                width: 90%;
                margin-left: auto;
                margin-right: auto;
        
                &.first {
                    display: none;
                }
            }
        
            .card-info-container {

                display: flex;
                justify-content: center;
                justify-items: center;
                padding: 1rem 0rem;
        
                .img-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
        
                    .info-img {
                        width: 18rem;
                    }
                }
        
                .text-container {
                    padding-left: 1rem;
        
                    .text {
                        font-size: 20px;
                    }
                }
            }
        }

    }

}

// Home media
.Home-actores-container {

    background-color: $pink-light;
    padding: 3rem 0rem;

    .text-container {
    
        display: flex;
        justify-content: center;

        .title {
            font-size: 42px;
            text-align: center;
            font-family: $Ancizar-bold-serif;
            color: $pink;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 24px;
            }
        }

    }

    .cards-actor-container {

        padding-top: 3rem;

        .card-actor-space {

            padding: 1rem 2rem;
            display: flex;

            .card-actor-container {
                box-shadow: 8px 8px 10px #929292e3;
                border-radius: 8px;
                background-color: $white;
                align-content: space-between;
                overflow: hidden;
                transition-duration: 0.2s;
            
                .title-container {
            
                    display: flex;
                    justify-content: center;
                    padding: 0.8rem 0rem;
                    min-height: 5.2rem;
                    align-items: center;
                    background-blend-mode:soft-light;
                    background-image: url(../../../assets/images/background_left.svg);
                    background-position: left;
                    background-repeat: no-repeat;
                    background-size:contain;

                    &.blue-color {
                        background-color: $blue;
                        color: $black;
                    }

                    &.orange-color {
                        background-color: $orange;
                        color: $white;
                    }

                    &.pink-color {
                        background-color: $pink;
                        color: $black;
                    }

                    &.purple-color {
                        background-color: $purple;
                        color: $white;
                    }
            
                    .title {
                        font-size: 22px;
                        font-family: $Ancizar-bold-serif;
                        width: 90%;
                        text-align: center;
                        line-height: 1 !important;

                        @media only screen and (max-width: $breakpoint-960) {
                            font-size: 20px;
                        }
                    }
                }
            
                .img-item {
            
                    display: flex;
                    justify-content: center;
                    padding: 1.5rem 0rem;
            
                    .info-img {
                        border-radius: 8px;
                        width: 60%;
                    }
                }
            
                .text-container {
            
                    display: flex;
                    justify-content: center;
                    padding-bottom: 1rem;
            
                    .text {
                        font-size: 19px;
                        font-family: $Ancizar-light-serif;
                        width: 90%;
                        text-align: center;

                        @media only screen and (max-width: $breakpoint-960) {
                            font-size: 18px;
                        }
                    }
                }

                &:hover {
                    transform: scale(1.05);
                }
            }

        }

    }

}