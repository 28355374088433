@import '../../../index.scss';

// Transformacion banner
.Transformacion-banner-container {

    justify-content: start;
    background-image: url(../../../assets/images/banner-transformacion.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgb(217, 72, 139, 0.6);
    background-blend-mode: soft-light;
    min-height: 300px;
    margin: 0;
    padding: 3rem 6rem;

    .logos-left {
        display: flex;
        align-items: start;

        .logo-1 {
            height: 2.5rem;
            padding-right: 1rem;
        }

        .logo-2 {
            height: 3rem;
            padding: 0rem 1rem;
        }

        .right-border {
            border-right: 2px solid $white;
            min-height: 3.2rem;
        }

    }

    .content-right {
        display: flex;
        align-items: center;

        .title {
            font-size: 75px;
            color: $white;
            text-align: right;
            font-family: $Ancizar-bold-serif;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 35px;
                text-align: center;
            }
        }

    }

}

// Transformacion description
.Transformacion-description-container {

    background-color: $pink-light;
    padding: 3rem 0rem;

    .text-container {
    
        display: flex;
        justify-content: center;
        padding: 1.5rem 0rem;

        .title {
            font-size: 30px;
            text-align: center;
            font-family: $Ancizar-bold-serif;
            color: $gray;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 24px;
            }
        }

        .text-info {
            font-size: 24px;
            font-family: $Ancizar-light-serif;
            text-align: center;
            color: $gray;
            line-height: 1.2 !important;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 20px;
            }
        }

    }

}

// Transformacion acciones
.Transformacion-acciones-container {

    background-color: $white;
    padding: 3rem 0rem;

    .text-container {

        display: flex;
        justify-content: center;
        padding: 1.5rem 0rem;

        .title {
            font-size: 40px;
            text-align: center;
            font-family: $Ancizar-bold-serif;
            color: $purple;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 24px;
            }
        }

    }

    .sections-container {

        display: flex;
        justify-content: center;
        padding: 2rem 0 0 0;

        @media only screen and (max-width: $breakpoint-960) {
            display: block;
        }

        .title-2 {
            font-size: 30px;
            text-align: center;
            font-family: $Ancizar-bold-serif;
            color: $gray;
            cursor: pointer;
            border-top: 2px solid #b7b7b7;
            border-bottom: 2px solid #b7b7b7;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 22px;
            }

            &.border-right {
                border-right: 3px solid $pink;

                @media only screen and (max-width: $breakpoint-960) {
                    border-right: none;
                }
            }

            &.active-item {
                background-color: $pink-light;
            }

            &:hover {
                background-color: $pink-light;
            }
        }
    }

    .Directivos-container {
    
        background-color: $white;
        padding: 2rem 0rem;
    
        .text-container {
    
            display: flex;
            justify-content: center;
            padding: 1.5rem 0rem;
    
            .title {
                font-size: 35px;
                text-align: center;
                font-family: $Ancizar-bold-serif;
                color: rgb(51, 51, 51, 0.8);
                ;
        
                @media only screen and (max-width: $breakpoint-960) {
                    font-size: 24px;
                }
            }
        
            .text-info {
                font-size: 24px;
                font-family: $Ancizar-light-serif;
                color: $gray;
                line-height: 1.2 !important;
                text-align: center;
        
                @media only screen and (max-width: $breakpoint-960) {
                    font-size: 20px;
                }
            }
    
        }

        .directivos-carousel {
            padding: 2rem 4rem;

            .directivos-carousel-slide {
                display: flex;
                justify-content: center;
            }
        }

        .card-directivos-container {
            box-shadow: 5px 5px 10px #929292e3;
            border-radius: 8px;
            background-color: $white;
            align-content: space-between;
            overflow: hidden;
            transition-duration: 0.2s;

            .title-container {

                display: flex;
                justify-content: center;
                padding: 0.8rem 0rem;
                min-height: 5.2rem;
                align-items: center;
                background-color: $pink;
                color: $white;

                .title {
                    font-size: 22px;
                    font-family: $Ancizar-bold-serif;
                    width: 90%;
                    text-align: center;
                    line-height: 1 !important;
                }
            }

            .img-item {

                display: flex;
                justify-content: center;
                padding-top: 1.5rem;

                .info-img {
                    border-radius: 8px;
                    width: 70%;
                    pointer-events: none;
                }
            }

            .text-container {

                display: flex;
                justify-content: center;
                padding-bottom: 1rem;

                .text {
                    font-size: 19px;
                    font-family: $Ancizar-light-serif;
                    width: 90%;
                    text-align: center;
                }
            }

            &:hover {
                transform: scale(1.05);
            }
        }
    
    }

    .Docentes-container {

        background-color: $white;
        padding: 1rem 0rem;

        .text-container {

            display: flex;
            justify-content: center;
            padding: 1.5rem 0rem;

            .title {
                font-size: 35px;
                text-align: center;
                font-family: $Ancizar-bold-serif;
                color: rgb(51, 51, 51, 0.8);
                ;

                @media only screen and (max-width: $breakpoint-960) {
                    font-size: 24px;
                }
            }

            .text-info {
                font-size: 24px;
                font-family: $Ancizar-light-serif;
                color: $gray;
                line-height: 1.2 !important;
                text-align: center;

                @media only screen and (max-width: $breakpoint-960) {
                    font-size: 20px;
                }
            }

        }

        .cards-docentes-container {

            padding-top: 3rem;

            .card-docentes-space {

                padding: 1rem 1rem;
                display: flex;

                .card-docentes-container {
                    box-shadow: 5px 5px 10px #929292e3;
                    border-radius: 8px;
                    background-color: $white;
                    align-content: space-between;
                    overflow: hidden;

                    .title-container {

                        display: flex;
                        justify-content: center;
                        padding: 0.8rem 0rem;
                        min-height: 5.2rem;
                        align-items: center;

                        .title {
                            font-size: 22px;
                            font-family: $Ancizar-bold-serif;
                            width: 90%;
                            line-height: 1 !important;
                        }
                    }

                    .img-item {

                        display: flex;
                        justify-content: center;

                        .info-img {
                            width: 100%;
                            height: 15rem;
                            object-fit: cover;
                            object-position: top;
                        }
                    }

                    .text-container {

                        display: flex;
                        justify-content: center;
                        padding-bottom: 1rem;

                        .text {
                            font-size: 19px;
                            font-family: $Ancizar-light-serif;
                            color: #333333cc;
                            width: 90%;
                        }
                    }

                    .button-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;

                        .testimony-button {

                            margin-top: 16px;

                            background: $purple;
                            border: solid 1px transparent;
                            border-radius: 12px;
                            color: $white;
                            margin: 25px;
                            width: auto;
                            height: auto;
                            min-width: 120px;
                            min-height: 40px;
                            font-size: 15px;

                            &:hover {
                                color: $white;
                                background: $pink;
                            }

                            .btn-txt {
                                font-size: 18px;
                                color: $white;
                                font-family: $Ancizar-regular-serif;
                            }
                        }
                    }
                }
            }
        }
    }

}

.modal-directivos {

    .text-container {

        display: flex;
        justify-content: center;

        .title {
            font-size: 30px;
            text-align: center;
            font-family: $Ancizar-bold-serif;
            color: rgb(51, 51, 51, 0.8);

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 24px;
            }
        }

        .text-info {
            font-size: 20px;
            font-family: $Ancizar-light-serif;
            color: $gray;
            line-height: 1.2 !important;
            padding: 0 2rem 2rem 2rem;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 20px;
            }
        }

    }

    .video-container {

        margin: 2rem 0rem;
        display: flex;
        justify-content: center;

        .video-item {
            width: 90%;
            height: 25rem;
        }

    }

}

// Transformacion manifiesto
.Transformacion-manifiesto-container {

    background-color: $white;
    padding: 3rem 0rem;

    .separator {
        border-width: 2px;
        border-color: #b7b7b7;
        margin-left: auto;
        margin-right: auto;
    }

    .text-container {

        display: flex;
        justify-content: center;
        padding: 1.5rem 0rem;
        align-items: center;

        .title {
            font-size: 35px;
            text-align: center;
            font-family: $Ancizar-bold-serif;
            color: rgb(51, 51, 51, 0.8);
            ;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 24px;
            }
        }

        .text-info {
            font-size: 24px;
            font-family: $Ancizar-light-serif;
            color: $gray;
            line-height: 1.2 !important;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 20px;
            }
        }

    }

    

}
.img-container {
    justify-content: center;
    display: flex;
    align-items: center;
    padding-top: 2rem;
    transition-duration: 0.2s;
    cursor: pointer;

    .info-img {
        width: 35rem;
        height: 50rem;

        // &:hover {
        //     transform: scale(1.05);
        // }

        @media only screen and (max-width: $breakpoint-960) {
            width: 10rem;
        }
    }
}

// Transformacion classroom
.Transformacion-classroom-container {

    background-color: $white;
    padding: 3rem 0rem;

    .separator {
        border-width: 2px;
        border-color: #b7b7b7;
        margin-left: auto;
        margin-right: auto;
    }

    .text-container {

        display: flex;
        justify-content: center;
        padding: 1.5rem 0rem;
        align-items: center;

        .title {
            font-size: 35px;
            text-align: center;
            font-family: $Ancizar-bold-serif;
            color: rgb(51, 51, 51, 0.8);;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 24px;
            }
        }

        .text-info {
            font-size: 25px;
            font-family: $Ancizar-light-serif;
            color: $gray;
            line-height: 1.2 !important;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 20px;
                padding-bottom: 2rem;
            }
        }

    }

    .img-container {
        justify-content: center;
        display: flex;
        align-items: center;

        .info-img {
            width: 20rem;
            height: auto;

            @media only screen and (max-width: $breakpoint-960) {
                width: 10rem;
            }
        }
    }

    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;

        .submit-button {

            margin-top: 16px;

            background: $purple;
            border: solid 1px transparent;
            border-radius: 12px;
            color: $white;
            margin: 25px;
            width: auto;
            height: auto;
            min-width: 120px;
            min-height: 40px;
            font-size: 15px;

            &:hover {
                color: $white;
                background: $pink;
            }

            .btn-txt {
                font-size: 18px;
                color: $white;
                font-family: $Ancizar-regular-serif;
            }
        }
    }

}