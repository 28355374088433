@import '../../../index.scss';

.navigation-container {

    height: auto;

    .logo-principal {
        position: absolute;
        background: no-repeat url(../../../assets/images/white_background.svg);
        height: 11.25rem;
        width: 29.7rem;
        z-index: 999;
        background-size: cover;
        display: flex;

        @media only screen and (max-width: $breakpoint-960) {
            background: none;
            position: relative;
            height: auto;
            width: auto;
            justify-content: center;
        }

        .logo-nav {
            height: 4.5rem;
            margin: 2rem 2.3rem;

            @media only screen and (max-width: $breakpoint-960) {
                height: 2.5rem;
            }
        }
    }

    .top-navigation {

        background-color: $blue;
        padding: 2rem;
        display: flex;
        align-items: center;
        justify-content: end;

        @media only screen and (max-width: $breakpoint-960) {
            background-image: none;
            justify-content: center;
        }

        .logo-1-nav {
            height: 3.5rem;
            padding-right: 1rem;

            @media only screen and (max-width: $breakpoint-960) {
                height: 2.5rem;
            }
        }
    
        .logo-2-nav {
            height: 4rem;
            padding: 0rem 1rem;

            @media only screen and (max-width: $breakpoint-960) {
                height: 3rem;
            }
        }

        .right-border {
            border-right: 3px solid $white;
            min-height: 4.5rem;

            @media only screen and (max-width: $breakpoint-960) {
                min-height: 3rem;
            }
        }

    }

    .bot-navigation {

        background-color: $purple;
        display: flex;
        justify-content: end;
        align-items: center;
        padding: 0rem 1rem;

        @media only screen and (max-width: $breakpoint-960) {
            justify-content: center;
        }

        .right-border {
            border-right: 2px solid $white;
            min-height: 1.3rem;
            border-radius: 3px; 

            @media only screen and (max-width: $breakpoint-960) {
                border-right: none;
            }
        }

        .section-container {

            color: $white;
            padding: 0rem 1rem;
            font-size: 1.25rem;
            font-family: $Ancizar-regular-serif;

            @media only screen and (max-width: $breakpoint-960) {
                margin: 1rem 0;
            }

            &.nav-item {

                cursor: pointer;
                padding: 0.8rem;

                @media only screen and (max-width: $breakpoint-960) {
                    margin: 0;
                }

                &:hover {
                    background-color: #9c39a7;
                }

                &.active {
                    background-color: #7a139c; // A darker color for active state
                }
            }

        }

    }

}