@import '../../index.scss';

// Transformacion banner
.Transformacion-banner-container {

    justify-content: start;
    background-image: url(../../assets/images/banner-transformacion.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgb(217, 72, 139, 0.6);
    background-blend-mode: soft-light;
    min-height: 300px;
    margin: 0;
    padding: 3rem 6rem;

    .logos-left {
        display: flex;
        align-items: start;

        .logo-1 {
            height: 2.5rem;
            padding-right: 1rem;
        }

        .logo-2 {
            height: 3rem;
            padding: 0rem 1rem;
        }

        .right-border {
            border-right: 2px solid $white;
            min-height: 3.2rem;
        }

    }

    .content-right {
        display: flex;
        align-items: center;

        .title {
            font-size: 75px;
            color: $white;
            text-align: right;
            font-family: $Ancizar-bold-serif;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 35px;
                text-align: center;
            }
        }

    }

}

// Voces banner
.Voces-banner-container {

    justify-content: start;
    background-image: url(../../assets/images/banner-voces.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgb(141, 23, 170, 0.6);
    background-blend-mode: soft-light;
    min-height: 300px;
    margin: 0;
    padding: 3rem 6rem;

    .logos-left {
        display: flex;
        align-items: start;

        .logo-1 {
            height: 2.5rem;
            padding-right: 1rem;
        }

        .logo-2 {
            height: 3rem;
            padding: 0rem 1rem;
        }

        .right-border {
            border-right: 2px solid $white;
            min-height: 3.2rem;
        }

    }

    .content-right {
        display: flex;
        align-items: center;

        .title {
            font-size: 75px;
            color: $white;
            text-align: right;
            font-family: $Ancizar-bold-serif;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
            
            @media only screen and (max-width: $breakpoint-960) {
                font-size: 35px;
                text-align: center;
            }
        }

    }

}

// Voces description
.Voces-description-container {

    background-color: $purple-light;
    padding: 3rem 0rem;

    .text-container {
    
        display: flex;
        justify-content: center;
        padding: 1.5rem 0rem;

        .title {
            font-size: 30px;
            text-align: center;
            font-family: $Ancizar-bold-serif;
            color: $gray;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 24px;
            }
        }

        .text-info {
            font-size: 24px;
            font-family: $Ancizar-light-serif;
            text-align: center;
            color: $gray;
            line-height: 1.2 !important;
            padding-bottom: 2rem;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 20px;
            }
        }

    }

}

// Videos testimoniales

.videos-testimoniales-container {

    background-color: $white;
    padding: 1rem 0;

    .text-container {
    
        display: flex;
        justify-content: center;
        padding: 1.5rem 0rem;

        .title {
            font-size: 40px;
            text-align: center;
            font-family: $Ancizar-bold-serif;
            color: $purple;

            @media only screen and (max-width: $breakpoint-960) {
                font-size: 24px;
            }
        }

    }

    .testimonio-container-info {

        border-right: 3px solid $pink;

        .text-container-2 {
        
            display: flex;
            justify-content: center;
            padding: 1.5rem 0rem;
    
            .title {
                font-size: 30px;
                font-family: $Ancizar-bold-serif;
                color: #5c5c5c;
    
                @media only screen and (max-width: $breakpoint-960) {
                    font-size: 24px;
                }
            }
    
            .txt {
                font-size: 20px;
                font-family: $Ancizar-light-serif;
                color: $gray;
                line-height: 1.2 !important;
    
                @media only screen and (max-width: $breakpoint-960) {
                    font-size: 20px;
                    padding-bottom: 2rem;
                }
            }

            .autor {
                font-size: 18px;
                font-family: $Ancizar-light-serif;
                color: #5c5c5c;
                line-height: 1.2 !important;
            
                @media only screen and (max-width: $breakpoint-960) {
                    font-size: 20px;
                    padding-bottom: 2rem;
                }
            }
    
        }

        .video-container {

            margin: 2rem 0rem;
            display: flex;
            justify-content: center;

            .video-item {

                &.horizontal {
                    aspect-ratio: 16 / 9;
                    width: 60%;
                }

                &.vertical {
                    aspect-ratio: 9 / 16;
                    width: 30%;
                }
            }

        }
    }

    .cards-testimonios-container {

        padding-top: 3rem;

        .card-testimonios-space {

            padding: 1rem 1rem;

            .card-testimonios-container {
                box-shadow: 5px 5px 10px #929292e3;
                border-radius: 8px;
                background-color: $white;
                align-content: space-between;
                overflow: hidden;

                .title-container {

                    display: flex;
                    justify-content: center;
                    padding: 0.8rem 0rem;
                    min-height: 5.2rem;
                    align-items: center;

                    .title {
                        font-size: 22px;
                        font-family: $Ancizar-bold-serif;
                        width: 90%;
                        line-height: 1 !important;
                    }
                }

                .img-item {

                    display: flex;
                    justify-content: center;

                    .info-img {
                        width: 100%;
                        height: 15rem;
                        object-fit: cover;
                        object-position: top;
                    }
                }

                .text-container {

                    display: flex;
                    justify-content: center;
                    padding-bottom: 1rem;

                    .text {
                        font-size: 19px;
                        font-family: $Ancizar-light-serif;
                        color: #333333cc;
                        width: 90%;
                    }
                }

                .button-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;

                    .testimony-button {

                        margin-top: 16px;

                        background: $purple;
                        border: solid 1px transparent;
                        border-radius: 12px;
                        color: $white;
                        margin: 25px;
                        width: auto;
                        height: auto;
                        min-width: 120px;
                        min-height: 40px;
                        font-size: 15px;

                        &:hover {
                            color: $white;
                            background: $pink;
                        }

                        .btn-txt {
                            font-size: 18px;
                            color: $white;
                            font-family: $Ancizar-regular-serif;
                        }
                    }
                }
            }
        }
    }
}
